@keyframes flyUp {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	10% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: translateY(-100vh) translateY(350px);
		opacity: 0;
	}
}

@keyframes flyUp2 {
	0% {
		top: 100;
		opacity: 0;
		transform: scale(0);
	}
	10% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		top: 0;
		opacity: 0;
		transform: scale(3);
	}
}

@keyframes flyUp2 {
	0% {
		top: 100;
		opacity: 0;
		transform: translateX(0) scale(0);
	}
	10% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		top: 0;
		opacity: 0;
		transform: scale(5);
	}
}

@keyframes shake {
	0%,
	100% {
		transform: translateX(0);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateX(-10px);
	}

	20%,
	40%,
	60% {
		transform: translateX(10px);
	}

	80% {
		transform: translateX(8px);
	}

	90% {
		transform: translateX(-8px) scale(5)
	}
}

.animate-svg {
	animation-name: flyUp2, shake;
	animation-duration: 5s;
	transition-timing-function: ease-in-out;
}